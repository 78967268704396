import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import WavyBackground from "../components/wavyBackground"
import { Helmet } from "react-helmet"

import FeaturedArticlesCarousel from "../components/featuredArticlesCarousel"
import FilteredArticlesGrid from "../components/filteredArticlesGrid"

import styles from "../styles/pages/blog.module.scss"

const Blog = (props) => {
  const categoryLabel=props.pageContext.label;
  const pageData = props.data.contentfulBlog
  const articles = props.data.allContentfulArticle.nodes
  const ldJson = (pageData.schema || []).reduce((resultArr, sObj) => {
    if (sObj?.internal?.content) {
      resultArr.push(JSON.parse(sObj.internal.content))
    }
    return resultArr
  }, [])

  return (
    <Layout color="navy" headerPadding page={props}>
      <SEO title={pageData.title} description={pageData.metaDescription} slug={props.pageContext.slug} />
      <Helmet>
        <link rel="stylesheet" type="text/css"  href="/style/print.css" media="print" />
        {ldJson.length && (
          <script type="application/ld+json">
            {JSON.stringify(ldJson)}
          </script>
        )}
      </Helmet>
      <section>
        <h1 className="sr-only">Encompass Tech Blog</h1>
        <FeaturedArticlesCarousel articles={pageData.featuredArticles} title={pageData.featuredArticlesTitle}/>
      </section>
      <section className={styles.gridWrapper}>
        <FilteredArticlesGrid
          articles={articles}
          tagsList={pageData.tags}
          categories={pageData.categories}
          categoryLabels={pageData.categoryLabels}
          filter={categoryLabel}
          blogSulg={props.pageContext.slug}
        />
      </section>
      <WavyBackground />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogPage($slug: String="blog") {
    contentfulBlog(slug: { eq: $slug }) {
      title
      metaDescription
      featuredArticlesTitle
      featuredArticles {
        title
        slug
        link
        description
        category {
          text
        }
        heroImage {
          title
          fluid(maxHeight: 480, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      tags {
        text
      }
      categoryLabels
      categories {
        text
      }
      schema {
        internal {
          content
        }
      }
    }
    allContentfulArticle(
      filter: {
        title: { ne: "Placeholder Article (Do Not Delete)" }
        hidden: { ne: true }
      }
    ) {
      nodes {
        ...ArticleThumbnail
      }
    }
  }
`
